<template>
  <div>
    <div v-if="isPC" class="header" style="height: 60px;  min-width: 960px;">
      <el-row type="flex">
        <el-col :span="6" style="display: flex; justify-content: center; align-items: center">
          <a class="navbar-brand" style="text-decoration: none; color: #fff" href="/">
            <el-row style="font-size: large">西安好享达科技有限公司</el-row>
            <el-row>XIAN HAXADA S&T CO.</el-row>
          </a>
        </el-col>
        <el-col :span="18" style="vertical-align: center">
          <el-menu class="el-menu-demo" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" unique-opened router>
            <template v-for="item in navigator">
              <el-submenu v-if="item.hasChild" :index="item.link" :key="item.name">
                <template slot="title">{{item.name}}</template>
                <template v-for="itemSub in item.children">
                  <el-menu-item :index="itemSub.link" :key="itemSub.name">{{itemSub.name}}</el-menu-item>
                </template>
              </el-submenu>
              <el-menu-item style="font-size: medium" v-else :index="item.link" :key="item.name">{{item.name}}</el-menu-item>
            </template>
          </el-menu>
        </el-col>
      </el-row>
    </div>

    <div v-else class="header" style="height: 100px">
      <div style="display: flex; justify-content: center; align-items: center">
        <a class="navbar-brand" style="text-decoration: none; color: #fff" href="/">
          <el-row style="font-size: large">西安好享达科技有限公司</el-row>
          <el-row>XIAN HAXADA S&T CO.</el-row>
        </a>
      </div>
      <div style="vertical-align: center">
        <el-menu class="el-menu-demo" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" unique-opened router>
          <template v-for="item in navigator">
            <el-submenu v-if="item.hasChild" :index="item.link" :key="item.name">
              <template slot="title">{{item.name}}</template>
              <template v-for="itemSub in item.children">
                <el-menu-item :index="itemSub.link" :key="itemSub.name">{{itemSub.name}}</el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item style="font-size: medium" v-else :index="item.link" :key="item.name">{{item.name}}</el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data () {
    return {
      isPC: true,
      navigator: [{
        link: '',
        name: '产品',
        hasChild: true,
        children: [{
          name: '信息平台',
          link: '/product/1'
        }, {
          name: '作业仿真',
          link: '/product/2'
        }, {
          name: '设计网站',
          link: '/product/3'
        }]
      }, {
        link: '/service',
        name: '服务',
        hasChild: false
      }, {
        link: '/company',
        name: '公司',
        hasChild: false
      }, {
        link: '/dynamic',
        name: '动态',
        hasChild: false
      }]
    }
  },
  created() {
    this.browerTest()
  },
  methods: {
    browerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    }
    // handleSelect: function (key, keyPath) {
    //   console.log('handleSelect:', key, keyPath)
    //   console.log('key:', key.toString().substr(9))
    // }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  z-index: 9;
  width: 100%;
  /*height: 60px;*/
  background: #545c64;
}
</style>