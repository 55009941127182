<template>
  <div>
    <div v-if="isPC">
      <el-carousel :interval="5000" direction="horizontal" :height="height">
        <el-carousel-item v-for="item in srcList" :key="item.id">
          <!--        <el-image :src="item.src" fit="scale-down"></el-image>-->
          <img :src="item.src" width="100%" :alt="srcList[0]"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else style="margin-top: 50px">
      <el-image v-for="item in srcList" :key="item.id" :src="item.src"></el-image>
<!--      <el-carousel :interval="5000" direction="horizontal" :width="width">-->
<!--        <el-carousel-item v-for="item in srcList" :key="item.id">-->
<!--          &lt;!&ndash;        <el-image :src="item.src" fit="scale-down"></el-image>&ndash;&gt;-->
<!--          <img :src="item.src" width="100%"/>-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
<!--      <el-carousel :interval="5000" direction="horizontal" :width="width">-->
<!--        <el-carousel-item v-for="item in srcList" :key="item.id">-->
<!--            <el-image :src="item.src" fit="scale-down"></el-image>-->
<!--          <img :src="item.src" width="100%"/>&ndash;&gt;-->
<!--        </el-carousel-item>-->
<!--      </el-carousel>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      isPC: true,
      width: 0,
      height: 0,
      srcList: [
        {id: '1', src: require('../../assets/carousel/carousel1.jpg')},
        {id: '2', src: require('../../assets/carousel/carousel2.jpg')},
        {id: '3', src: require('../../assets/carousel/carousel3.jpg')}
      ]
    }
  },
  created() {
    this.height = window.screen.height * 0.6 + 'px'
    this.width = window.screen.width + 'px'
    // console.log('height:', this.height)
    // console.log('width:', this.width)
    this.brouwerTest()
  },
  methods: {
    brouwerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
