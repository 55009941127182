<template>
  <el-container>
    <el-header style="background: #545c64">
      <el-menu mode="horizontal" @select="handleSelect" background-color="#545c64" text-color="#fff" active-text-color="#545c64">
        <el-menu-item index="1"><img src="../../assets/logo.png" style="width: 40px"></el-menu-item>
        <el-menu-item index="2">
          <span style="color: #ffffff; font-size: large">西安好享达后台管理系统</span>
        </el-menu-item>
        <el-menu-item index="3" style="width: 150px; float: right">
          <el-dropdown trigger="click">
            <span style="color: #ffffff">欢迎您 {{userName}}<i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu>
            <el-dropdown-item divided @click.native="changePassword">修改密码</el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-container style="margin-top: 10px">
      <el-aside width="200px" style="margin-right: 10px">
        <el-menu class="el-menu-vertical-demo" @select="handleMenuSelect" style="height: 100%" background-color="#545c64" text-color="#fff"
                 active-text-color="#ffd04b" unique-opened router>
          <template v-for="item in navigator">
            <el-submenu v-if="item.hasChild" :index="item.link" :key="item.name">
              <template slot="title">
                <i :class="item.icon"></i>
                <span slot="title">{{item.name}}</span>
              </template>
              <template v-for="subItem in item.children">
                <el-menu-item :index="subItem.link" :key="subItem.name">
                  <span slot="title">{{subItem.name}}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else :index="item.link" :key="item.name">
              <i :class="item.icon"></i>
              <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "Home",
  data () {
    return {
      userName: '胡国峰',
      navigator: [{
        name: '首页',
        icon: 'el-icon-s-home',
        link: '/administrator/index',
        hasChild: false
      }, {
        name: '轮播图管理',
        icon: 'el-icon-s-help',
        link: '/administrator/carousel',
        hasChild: false
      }, {
        name: '产品管理',
        icon: 'el-icon-menu',
        link: '/administrator/product',
        hasChild: false
      }, {
        name: '服务管理',
        icon: 'el-icon-star-on',
        link: '/administrator/service',
        hasChild: false
      }, {
        name: '公司管理',
        icon: 'el-icon-s-promotion',
        link: '/administrator/company',
        hasChild: false
      }, {
        name: '动态管理',
        icon: 'el-icon-news',
        link: '/administrator/dynamic',
        hasChild: false
      }, {
        name: '系统管理',
        icon: 'el-icon-s-tools',
        link: '/administrator/system',
        hasChild: true,
        children: [{
          name: '用户管理',
          link: '/administrator/system'
        }, {
          name: '角色管理',
          link: '/administrator/system/role'
        }]
      }]
    }
  },
  methods: {
    handleSelect: function (index, indexPath) {
      console.log('handleSelect index:', index)
      console.log('handleSelect indexPath:', indexPath)
    },
    changePassword: function () {
      console.log('changePassword')
    },
    logout: function () {
      console.log('logout')
    },
    handleMenuSelect: function (index, indexPath) {
      console.log('handleMenuSelect index:', index)
      console.log('handleMenuSelect indexPath:', indexPath)
    },
  }
}
</script>

<style scoped>

</style>