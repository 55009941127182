<template>
  <div style="width: 100%; height: 100%; position: fixed; background: #3a5169;">
    <div class="login-container">
<!--      <h1 class="login-logo"></h1>-->
      <h3 style="text-align: center; color: white">西安好享达后台管理系统</h3>
      <el-form ref="accountForm" :model="account" :rules="rules" label-position="left" class="login-form">
        <el-form-item prop="username">
          <el-input type="text" v-model="account.username" auto-complete="off" placeholder="账号" @keyup.enter.native="handleLogin"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="account.password" auto-complete="off" placeholder="密码" @keyup.enter.native="handleLogin"></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="12">
            <el-checkbox v-model="checked" value="" class="checkBox"><div style="font-size: small">记住用户名</div></el-checkbox>
          </el-col>
          <el-col :span="4" :offset="8">
            <el-popover ref="popover" placement="right-start" title="密码丢失？" width="160px" trigger="hover" content="请联系管理员找回密码"></el-popover>
            <el-button type="text" v-popover:popover style="font-size: small">找回密码？</el-button>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width: 100%" @click.n.native.prevent="handleLogin" :loading="loading">登录</el-button>
        </el-form-item>
      </el-form>
      <el-col :span="24" class="footer">
        <div>&copy; 版权所有 西安好享达科技有限公司</div>
      </el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data () {
    return {
      loading: false,
      account: {
        username: 'guofenghu',
        password: '123456'
      },
      rules: {
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}]
      },
      checked: true
    }
  },
  methods: {
    setCookie: function (username, expireDays) {
      let expireDate = new Date()
      expireDate.setTime(expireDate.getTime() + 24 * 60 * 60 * 1000 * expireDays)
      window.document.cookie = 'username=' + username + ';path=/;expires=' + expireDate.toDateString()
    },
    getCookie: function () {
      let that = this
      if (document.cookie.length > 0) {
        let arr = document.cookie.split(';')
        for (let i = 0; i < arr.length; i++) {
          let arr1 = arr[i].split('=')
          if (arr1[0] === 'username') {
            that.account.username = arr1[1]
          }
        }
      }
    },
    clearCookie: function () {
      this.setCookie('', '')
    },
    handleLogin: function () {
      this.$refs.accountForm.validate((valid) => {
        if (valid) {
          this.loading =true
          if (this.checked === true) {
            console.info('checked:', this.checked)
            this.setCookie(this.account.username, 7)
          } else {
            console.info('checked:', this.checked)
            this.clearCookie()
          }
          this.loading = false
          this.$router.push({path: '/administrator/index'})
        } else {
          console.log('data invalid')
          return false
        }
      })
    }
  },
  mounted() {
    this.getCookie()
  }
}
</script>

<style scoped>
.login-container {
  display: block;
  width: 420px;
  height: 400px;
  border:1px solid red;
  position: fixed;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background: lightseagreen;
}
.login-form {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
}
.footer {
  margin: 15px 0;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
}
</style>