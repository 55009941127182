<template>
<div>
  <div v-if="isPC">
    <article v-for="item in companyList" :key="item.id">
      <h1 style="text-align: center">{{item.title}}</h1>
      <div style="display: flex; justify-content: center"><img :src="item.src" width="80%" alt=""></div>
      <p v-html="item.content" style="padding-left: 10%; padding-right: 10%"></p>
      <el-divider v-if="item.id !== '3'"></el-divider>
    </article>
  </div>
  <div v-else style="margin-top: 50px">
    <article v-for="item in companyList" :key="item.id">
      <h1 style="text-align: center">{{item.title}}</h1>
      <div style="display: flex; justify-content: center"><img :src="item.src" width="100%" alt=""></div>
      <p v-html="item.content"></p>
      <el-divider v-if="item.id !== '3'"></el-divider>
    </article>
  </div>
</div>
</template>

<script>
export default {
  name: "Company",
  data () {
    return {
      isPC: true,
      companyList: [
        {
          id: '1',
          title: '西安好享达科技有限公司',
          src: require('../../assets/company/N1.jpg'),
          content: '<p style="text-indent: 24px">西安好享达科技有限公司是一家集设计、开发、生产、销售、运维于一起的新型科技企业，2019年成立于陕西西安，主要从事智慧产品开发、软件开发、系统集成、及技术服务等经济活动，' +
              '为客户提供智能平台、作业仿真、网站设计等领域的需求分析、解决方案、产品设计、产品开发、自动化测试、软件部署、运维升级等一站式服务。公司拥有雄厚的科技力量和强大的科研实力，' +
              '倡导自主研发，合作共赢，勇于创新。公司注重技术积累，并加强与外部机构和专家的合作，紧跟时代和科技的步伐，不断提高产品的性能和品质，为客户创造更优质的服务。 </p>'+
              '<p style="text-indent: 24px">变是唯一的不变，我们拥抱变化，欢迎各类行家里手加入我们，或者和我们合作，共同实现我们的理想或想法，充分发挥大家的聪明才智，做到人尽其才、在工作中一起成长，为创造美好的明天共同努力。' +
              '希望通过我们的努力使生产数字化、生活便捷化、社会和谐化、人生艺术化，让每个人的生活更美好！ </p>'
        }, {
          id: '2',
          title: '我们的优势',
          src: require('../../assets/company/N2.jpg'),
          content: '<p style="text-indent: 24px">公司在物联网技术、微服务技术、软件仿真技术、地理信息技术、算法技术等方面有雄厚的技术积累，并具有信息资源管理平台、作业仿真、网站设计等方面产品的实际设计、开发、' +
              '运维经验，以及外部合作伙伴，可协作完成复杂系统的设计开发，我们以开放的心态，欢迎各界专家和我们合作，实现共赢和发展。</p>'+
              '<p style="text-indent: 24px">公司重视人才的引进、培养，做到人尽其才、物尽其用，竭力创造奋发向上的工作氛围、和谐友好的生活氛围。公司重视人员管理、制度管理、流程管理、及分配管理，让创造价值的人' +
              '获取相匹配的待遇。公司致力于员工持股，寻找志同道合的伙伴，为了理想而奋斗，让世界变得更好。 </p>'
        }, {
          id: '3',
          title: '联系我们',
          src: require('../../assets/company/N3.jpg'),
          content: '<p style="text-indent: 24px">邮箱：huguofeng001@126.com</p>'+
              '<p style="text-indent: 24px">微信：huguofeng001a</p>'
        }
      ]
    }
  },
  methods: {
    brouwerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    }
  },
  created() {
    this.brouwerTest()
  }
}
</script>

<style scoped>

</style>