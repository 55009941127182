<template>
<div>
  <div v-if="isPC">
    <article v-for="item in serviceList" :key="item.id">
      <h1 style="text-align: center">{{item.title}}</h1>
      <div style="display: flex; justify-content: center"><img :src="item.src" width="100%" alt=""></div>
      <p v-html="item.content" style="padding-left: 25px; padding-right: 25px"></p>
      <el-divider v-if="item.id !== '3'"></el-divider>
    </article>
  </div>
  <div v-else style="margin-top: 50px">
    <article v-for="item in serviceList" :key="item.id">
      <h1 style="text-align: center">{{item.title}}</h1>
      <div style="display: flex; justify-content: center"><img :src="item.src" width="100%" alt=""></div>
      <p v-html="item.content"></p>
      <el-divider v-if="item.id !== '3'"></el-divider>
    </article>
  </div>
</div>
</template>

<script>
export default {
  name: "Service",
  data () {
    return {
      isPC: true,
      serviceList: [
        {
          id: '1',
          title: '信息资源管理平台设计开发',
          src: require('../../assets/service/1/N1.jpg'),
          content: '<p style="text-indent: 24px">信息的基本作用就是消除人认识的不确定性，增强世界的有序性，对于现代企业来说，信息资源是一个企业赖以生存的重要因素，信息资源管理的核心内容就是资源的合理配置，' +
              '实现信息资源的充分开发和有效利用。在多层次组织和多元开发中，信息资源的形态呈现多样化趋势，各种形态的资源在形态转化中相互作用，成为一体，形成社会的信息资源结构，' +
              '由于信息资源结构复杂，易产生信息不对称，有必要对信息资源进行有效管理、开发和利用，促进资源的合理配置，提高资源的利用率。</p>'+
    '<p style="text-indent: 24px">水资源管理系统是将计算机技术、信息技术、网络通信、地理信息、数据分析等技术应用于水文水资源领域，实现水文水资源信息的监测管理、数值模拟、图形显示、水量水质分析、' +
              '水环境评价等功能，为水资源的科学管理、合理配置、规划决策提供支持服务，满足经济社会发展对水资源的需求，实现水资源的可持续利用。</p>'+
    '<p style="text-indent: 24px">社区矫正管理系统是将计算机技术、地理信息、网络通信、数据分析等技术应用于社会管理，运用电子安全监管手段对非羁押人员在社区进行矫正，实现人员管理和定位管理，' +
              '解决了各监所人满为患的紧张局面和财政经费短缺的问题，在不影响矫正人员改造的情况，体现社会的人文关怀。</p>'
        }, {
          id: '2',
          title: '作业仿真服务',
          src: require('../../assets/service/2/N1.jpg'),
          content: '<p style="text-indent: 24px">生产线仿真服务为企业提供专业的生产线仿真，包括生产线2D、3D建模，工艺流程编程、生产线运行模拟、瓶颈分析、时间分析、设计优化等，可对新建生产线进行可行性仿真，' +
              '也可对旧的生产线进行优化分析，找出瓶颈点，进行升级改造。</p>'+
              '<p style="text-indent: 24px">制造执行系统是一套面向制造企业车间执行层的生产信息化管理系统。MES可以为企业提供包括制造数据管理、计划排程管理、生产调度管理、库存管理、质量管理、人力资源管理、' +
              '工作中心/设备管理、工具工装管理、采购管理、成本管理、项目看板管理、生产过程控制、底层数据集成分析、上层数据集成分解等管理模块，为企业打造一个扎实、可靠、全面、' +
              '可行的制造协同管理平台。</p>'+
              '<p style="text-indent: 24px">现场作业数字化辅助支持系统是以装配级三维模型和标准化作业指导书为基础，构建交互式三维实操仿真平台，可广泛用于标准作业技能的培训和现场作业前的模拟训练，模拟安全交底、' +
              '技术交底，模拟作业全过程的工序工艺，并通过训练考核加深对技能的理解，是现场作业的重要辅助支持系统，对于有一定危险性的作业场合，可有效提高作业人员技能操作的准确性，' +
              '减少事故的发生。</p>'
        }, {
          id: '3',
          title: '网站设计开发服务',
          src: require('../../assets/service/3/N1.jpg'),
          content: '<p style="text-indent: 24px">网站设计服务为客户提供从产品需求、原型设计、产品开发、品质测试、到运维升级的一站式服务，网站可用手机、或电脑打开，结束了电脑网站不能用手机正确打开的历史，适应手机' +
              '作为主要客户端的现实，有利于企业宣传和品牌树立。</p>'
        }
      ]
    }
  },
  methods: {
    brouwerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    }
  },
  created() {
    this.brouwerTest()
  }
}
</script>

<style scoped>

</style>