<template>
  <div style="height: 60px; width: 100%; padding-top: 5px; background-color: #545c64; color: #ffffff; font-size: x-small; margin: 5px 0; text-align: center; line-height: 20px; text-decoration: none;">
    <div style="width: 100%; margin: 0 auto">
      <el-row>
        <a target="_blank" href="http://beian.miit.gov.cn/" title="域名信息备案管理系统" ref="nofollow">
          <p style="display: inline-block; height: 12px; line-height: 12px; margin: 0 0 0 0; color: #939393">
            <el-row style="margin-bottom: 5px">© 西安好享达科技有限公司 haxada.com</el-row>
            <el-row>版权所有 ICP证：陕ICP备20006592号</el-row></p>
        </a>
      </el-row>
      <el-row>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019402000036" style="display: inline-block; text-decoration: none; height: 12px; line-height: 12px">
          <img src="../../../assets/beian.png" style="float:left; width: 12px" alt=""/><p style="float: left; height: 12px; line-height: 12px; margin: 0 0 0 0; color: #939393">陕公网安备 61019402000036号</p></a>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>