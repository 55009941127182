<template>
<div>
<!--  <h1>Product</h1>-->
<!--  <div v-if="twoFlag"><h1>Product Two</h1></div>-->
<!--  <div v-else-if="threeFlag"><h1>Product Three</h1></div>-->
<!--  <div v-else><h1>Product One</h1></div>-->
  <div v-if="isPC">
    <div v-for="item in product" :key="item.id" style="width: 100%; margin: 10px auto; text-align: center">
      <img :src="item.src" width="80%" :alt="product[0].src">
<!--      <el-image :src="item.src"></el-image>-->
    </div>
<!--    <el-image v-for="item in product" :key="item.id" :src="item.src"></el-image>-->
  </div>
  <div v-else style="margin-top: 50px">
    <el-image v-for="item in product" :key="item.id" :src="item.src"></el-image>
  </div>
</div>
</template>

<script>
export default {
  name: "Product",
  data () {
    return {
      isPC: true,
      productOne: [
        {id: '1', src: require('../../assets/product/1/N1.jpg')},
        {id: '2', src: require('../../assets/product/1/N2.jpg')},
        {id: '3', src: require('../../assets/product/1/N3.jpg')}
      ],
      productTwo: [
        {id: '1', src: require('../../assets/product/2/N1.jpg')},
        {id: '2', src: require('../../assets/product/2/N2.jpg')},
        {id: '3', src: require('../../assets/product/2/N3.jpg')}
      ],
      productThree: [
        {id: '1', src: require('../../assets/product/3/N1.jpg')},
        {id: '2', src: require('../../assets/product/3/N2.jpg')},
        {id: '3', src: require('../../assets/product/3/N3.jpg')}
      ],
      product: []
    }
  },
  methods: {
    brouwerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    },
    productSelect: function () {
      // console.log('productSelect')
      let id = this.$route.params.id
      // console.log('id', id)
      switch (id) {
        case '2':
          this.product = this.productTwo
          break
        case '3':
          this.product = this.productThree
          break
        default:
          this.product = this.productOne
          break
      }
    }
  },
  watch: {
    '$route.path': function () {
      this.productSelect()
    }
  },
  mounted() {
    this.productSelect()
  },
  created() {
    this.brouwerTest()
  }
}
</script>

<style scoped>

</style>