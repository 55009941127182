import Vue from 'vue'
import VueRouter from "vue-router"

import Home from '@/components/user/base/Home'
import Index from '@/components/user/Index'
import Product from '@/components/user/Product'
import Service from '@/components/user/Service'
import Company from '@/components/user/Company'
import Dynamic from '@/components/user/dynamic/Dynamic'
import DynamicDetail from '@/components/user/dynamic/Detail'

import Login from '@/components/administrator/Login'
import adminHome from '@/components/administrator/Home'
import adminIndex from '@/components/administrator/Index'
import adminCarousel from '@/components/administrator/carousel/List'
import adminProduct from '@/components/administrator/product/List'
import adminService from '@/components/administrator/service/List'
import adminCompany from '@/components/administrator/company/List'
import adminDynamic from '@/components/administrator/dynamic/List'
import adminSystemUser from '@/components/administrator/system/user/List'
import adminSystemRole from '@/components/administrator/system/role/List'

Vue.use(VueRouter)

const user_routers=[
    {
        path: '*',
        redirect: '/administrator/login'
    },
    {
        path:'/',
        name: 'index',
        component: Home,
        children: [
            {path: '', component: Index},
            {path: 'product/:id', component: Product},
            {path: 'service', component: Service},
            {path: 'company', component: Company},
            {path: 'dynamic', component: Dynamic},
            {path: 'dynamic/detail', name: 'dynamicDetail', component: DynamicDetail}
        ]
    }
]

const administrator_routes = [
    {
        path: '/administrator/login',
        name: 'login',
        component: Login
    }, {
        path: '/administrator',
        redirect: 'administrator/index',
        component: adminHome,
        children: [
            {path: '/administrator/index', component: adminIndex},
            {path: '/administrator/carousel', component: adminCarousel},
            {path: '/administrator/product', component: adminProduct},
            {path: '/administrator/service', component: adminService},
            {path: '/administrator/company', component: adminCompany},
            {path: '/administrator/dynamic', component: adminDynamic},
            {path: '/administrator/system', component: adminSystemUser},
            {path: '/administrator/system/role', component: adminSystemRole}
        ]
    }
]

let router = new VueRouter({
    mode: 'history',
    routes: user_routers.concat(administrator_routes)
})

/*router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/administrator/login')) {
        window.sessionStorage.removeItem('Authorization');
        next()
    } else if (to.path.startsWith('/administrator')) {
        let Authorization = window.sessionStorage.getItem('Authorization');
        if (!Authorization) {
            next({path: 'administrator/login'})
        } else {
            next()
        }
    } else {
        next()
    }
})*/

export default router