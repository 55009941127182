<template>
<div>
  <el-breadcrumb separator="/" style="margin-bottom: 15px">
    <el-breadcrumb-item><b>轮播图管理</b></el-breadcrumb-item>
    <el-breadcrumb-item>轮播图列表</el-breadcrumb-item>
  </el-breadcrumb>
  <el-form :inline="true" :model="formInline">
    <el-form-item>
      <el-input placeholder="请输入标题" v-model="formInline.inputQuery">
        <el-button type="primary" slot="append" icon="el-icon-search" @click="queryButton">查询</el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="addButton">添加</el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="batchDeleteButton">批量删除</el-button>
    </el-form-item>
  </el-form>
  <el-table :data="tableData" border highlight-current-row v-loading="dataLoading" :header-cell-style="{background: '#273c75',color: 'white'}">
    <el-table-column type="selection" width="50"></el-table-column>
    <el-table-column type="index" width="60"></el-table-column>
    <el-table-column prop="title" label="标题" width="150"></el-table-column>
    <el-table-column prop="image" label="图像" min-width="250">
      <template slot-scope="scope">
          <img :src="scope.row.image" style="height: 200px" alt="">
      </template>
    </el-table-column>
    <el-table-column prop="sort" label="排序" width="100"></el-table-column>
    <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
    <el-table-column prop="operate" label="操作" width="200">
      <template v-slot="slotData">
        <el-button type="primary" @click="editButton(slotData.row)">编辑</el-button>
        <el-button type="danger" @click="deleteButton(slotData.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-dialog :title="titleDialog" width="500px" :visible.sync="dialogVisible" :modal-append-to-body='false' center>
        <hr>
        <p style="color: darkgray; text-indent: 3%">支持上传jpg/png文件，文件大少不超过500M</p>
    <el-upload action="uploadAction"
               class="upload-demo"
               :show-file-list="false"
               :before-upload="beforeUpload"
               :http-request="uploadPicture">
      <div v-if="!progressFlag">
        <div v-if="formDialog.id">{{formDialog.name}}, {{formDialog.file}}</div>
        <!--        <i v-else class="el-icon-plus" id="avatar-uploader-icon"></i>-->
        <div v-else-if="formDialog.file">{{formDialog.file}}</div>
        <el-button v-else type="primary">点击上传</el-button>
      </div>
      <div v-show="progressFlag">
        <el-progress :percentage="uploadPercentage" :text-inside="true" :stroke-width="26"
                     style="width: 500px"></el-progress>
      </div>
    </el-upload>
    <el-form :model="formDialog" class="demo-form-inline" style="margin-top: 15px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="formDialog.title" :disabled="dialogEditDisable" auto-complete="off" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model.number="formDialog.sort" auto-complete="off" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveButton">保存</el-button>
        <el-button type="primary" @click.native="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</div>
</template>

<script>
export default {
  name: "List",
  data () {
    return {
      formInline: {
        inputQuery: ''
      },
      dataLoading: false,
      tableData: [
        {title: '学习资源平台', image: require('../../../assets/carousel/carousel1.jpg'), sort: 1, createTime: '2021-11-25 18:33:42'},
        {title: '健康资源平台', image: require('../../../assets/carousel/carousel2.jpg'), sort: 2, createTime: '2021-11-25 22:33:42'},
        {title: '工作资源平台', image: require('../../../assets/carousel/carousel3.jpg'), sort: 3, createTime: '2021-11-25 22:33:42'}
      ],
      titleDialog: '新增轮播图',
      dialogVisible: false,
      progressFlag: false,
      uploadPercentage: 0,
      formDialog: {
        id: '',
        file: '',
        title: '',
        sort: ''
      },
      dialogEditDisable: false
    }
  },
  methods: {
    queryButton: function () {
      console.log('queryButton')
    },
    addButton: function () {
      console.log('addButton')
      this.titleDialog = '新增轮播图'
      this.formDialog = {}
      this.dialogEditDisable = false
      this.dialogVisible = true
    },
    batchDeleteButton: function () {
      console.log('batchDeleteButton')
    },
    editButton: function () {
      console.log('editButton')
      this.titleDialog = '修改轮播图'
      this.formDialog.id = '1'
      this.formDialog.file = 'carousel1.png'
      this.formDialog.title = '学习资源平台'
      this.formDialog.sort = '1'
      this.dialogEditDisable = true
      this.dialogVisible = true
    },
    deleteButton: function () {
      console.log('deleteButton')
    },
    saveButton: function () {
      console.log('saveButton')
    },
    beforeUpload: function (file) {
      console.log('beforeVideoUpload', file.type)
      const isLt500M = file.size / 1024 / 1024 < 500
      if (['image/png'].indexOf(file.type) === -1) {
        this.$message.error('请上传正确的视频格式！')
        return false
      }
      if (!isLt500M) {
        console.log('上传视频大小不能超过500M')
        return false
      }
    },
    uploadPicture: function (param) {
      console.log('uploadPicture', param)
      /*this.progressFlag = true
      this.formDialog.file = param.file.name
      this.formDialog.path = 'script'
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('path', 'script')
      const config = {
        onUploadProgress: progressEvent => {
          this.videoUploadPercentage = Number((progressEvent.loaded / progressEvent.total * 100).toFixed(2))
        },
        timeout: 1000000
      }
      uploadFileApi(formData, config).then(data => {
        console.log(data)
        if (data.status) {
          if (this.videoUploadPercentage === 100) {
            this.progressFlag = false
            this.videoUploadPercentage = 0
          }
        } else {
          console.log('lesson/uploadVideo: ', data)
        }
      }).catch(error => {
        console.log('lesson/uploadVideo e: ', error)
      })*/
    }
  }
}
</script>

<style scoped>

</style>