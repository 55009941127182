<template>
<el-container>
  <el-header style="padding: 0">
    <Header></Header>
  </el-header>
  <el-main style="padding: 0">
    <router-view></router-view>
  </el-main>
  <el-footer style="padding: 0">
    <Footer></Footer>
  </el-footer>
</el-container>
</template>

<script>
import Header from "@/components/user/base/Header";
import Footer from "@/components/user/base/Footer";
export default {
  name: "Home",
  components: {Footer, Header}
}
</script>

<style scoped>

</style>