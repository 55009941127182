<template>
<div>
  <el-breadcrumb separator="/" style="margin-bottom: 15px">
    <el-breadcrumb-item><b>首页</b></el-breadcrumb-item>
    <el-breadcrumb-item>数量统计</el-breadcrumb-item>
  </el-breadcrumb>
  <el-row class="first-row" :gutter="20">
    <el-col :span="6">
      <div class="block b1">轮播图数量
        <span class="number">{{carouselSum}}</span> </div>
    </el-col>
  </el-row>
  <el-row class="first-row" :gutter="20">
    <el-col :span="6">
      <div class="block b2">产品数量
      <span class="number">{{product.length}}</span> </div>
    </el-col>
    <el-col :span="6">
      <div class="block b3">产品1: {{product[0].name}}
        <span class="number">{{product[0].sum}}</span> </div>
    </el-col>
    <el-col :span="6">
      <div class="block b4">产品2: {{product[1].name}}
        <span class="number">{{product[1].sum}}</span> </div>
    </el-col>
    <el-col :span="6">
      <div class="block b5">产品3: {{product[2].name}}
        <span class="number">{{product[2].sum}}</span> </div>
    </el-col>
  </el-row>
  <el-row class="first-row" :gutter="20">
    <el-col :span="6">
      <div class="block b6">服务类型
        <span class="number">{{serviceSum}}</span> </div>
    </el-col>
  </el-row>
  <el-row class="first-row" :gutter="20">
    <el-col :span="6">
      <div class="block b7">公司介绍文档数量
        <span class="number">{{companySum}}</span> </div>
    </el-col>
  </el-row>
  <el-row class="first-row" :gutter="20">
    <el-col :span="6">
      <div class="block b8">公司动态文档数量
        <span class="number">{{dynamicSum}}</span> </div>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: "index",
  data () {
    return {
      carouselSum: 3,
      product: [
        {name: '资源管理', sum: 3},
        {name: '作业仿真', sum: 3},
        {name: '网站设计', sum: 3}
      ],
      serviceSum: 3,
      companySum: 3,
      dynamicSum: 3
    }
  }
}
</script>

<style scoped>
.block {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
}
.block .number {
  display: inline-block;
  float: right;
  font-size: 50px;
}
.first-row .block {height: 80px; color: #fff; border: none;}
.first-row .block.b1 {background-color: #5352ed;}
.first-row .block.b2 {background-color: #C4A466;}
.first-row .block.b3 {background-color: #006699;}
.first-row .block.b4 {background-color: #F36997;}
.first-row .block.b5 {background-color: #4CABCE;}
.first-row .block.b6 {background-color: #2ed573;}
.first-row .block.b7 {background-color: #ff6348;}
.first-row .block.b8 {background-color: #747d8c;}
</style>