<template>
<div>
  <h1>company/list</h1>
</div>
</template>

<script>
export default {
  name: "List"
}
</script>

<style scoped>

</style>