<template>
<div>
  <div v-if="isPC" style="margin-left: 25px; margin-right: 25px">
    <div v-if="rowLen>0">
      <el-row v-for="item in Number(rowLen)" :key="item" style="margin-top: 10px">
        <el-col :span="5" :offset="subItem>1 ? 1 : 0" v-for="subItem in Number(columnLen)" :key="subItem">
          <div @click="handleNewsCard(Number(newsList[columnLen*(item-1)+subItem-1].id))" style="cursor: pointer">
            <el-card style="min-width: 250px">
              <img :src="newsList[columnLen*(item-1)+subItem-1].src" style="width: 100%; display: block" alt="">
              <div style="font-weight: bold">{{newsList[columnLen*(item-1)+subItem-1].title}}</div>
              <div>{{newsList[columnLen*(item-1)+subItem-1].abstract}}</div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="remainder>0">
      <el-row style="margin-top: 10px">
        <el-col :span="5" :offset="subItem>1 ? 1 : 0" v-for="subItem in Number(remainder)" :key="subItem">
          <div @click="handleNewsCard(newsList[columnLen*rowLen+subItem-1].id)" style="cursor: pointer">
            <el-card style="min-width: 250px">
              <img :src="newsList[columnLen*rowLen+subItem-1].src" style="width: 100%; display: block" alt="">
              <div>{{newsList[columnLen*rowLen+subItem-1].title}}</div>
              <div>{{newsList[columnLen*rowLen+subItem-1].abstract}}</div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>

  <div v-else style="margin-top: 50px">
    <div v-if="rowLen>0">
      <el-row v-for="item in Number(rowLen)" :key="item" style="margin-top: 15px; margin-left: 20px">
        <el-col :span="5" :offset="subItem>1 ? 1 : 0" v-for="subItem in Number(columnLen)" :key="subItem">
          <div @click="handleNewsCard(newsList[columnLen*(item-1)+subItem-1].id)" style="cursor: pointer">
            <el-card style="width: 300px">
              <img :src="newsList[columnLen*(item-1)+subItem-1].src" style="width: 100%; display: block" alt="">
              <div style="font-weight: bold">{{newsList[columnLen*(item-1)+subItem-1].title}}</div>
              <div>{{newsList[columnLen*(item-1)+subItem-1].abstract}}</div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-if="remainder>0">
      <el-row style="margin-top: 10px">
        <el-col :span="5" :offset="subItem>1 ? 1 : 0" v-for="subItem in Number(remainder)" :key="subItem">
          <div @click="handleNewsCard(newsList[columnLen*rowLen+subItem-1].id)" style="cursor: pointer">
            <el-card style="width: 250px">
              <img :src="newsList[columnLen*rowLen+subItem-1].src" style="width: 100%; display: block" alt="">
              <div>{{newsList[columnLen*rowLen+subItem-1].title}}</div>
              <div>{{newsList[columnLen*rowLen+subItem-1].abstract}}</div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Dynamic",
  data () {
    return {
      isPC: true,
      rowLen: '0',
      columnLen: '5',
      remainder: '',
      newsList: [
        {
          id: '1',
          title: '西安好享达隆重成立',
          abstract: '改变世界，创造美好生活',
          src: require('../../../assets/dynamic/N1.jpg'),
          content: '<p style="text-indent: 24px">2019年9月20日，西安好享达科技有限公司在西安隆重成立，我们将以开放、合作、共赢的心态，欢迎各方专家、伙伴与我们一道，共同实现我们的理想或想法，充分发挥大家的聪明才智，做到人尽其才、在工作中一起成长，为创造美好的明天共同努力。希望通过我们的努力使生产数字化、生活便捷化、社会和谐化、人生艺术化，让每个人的生活更美好！ </p>'
        }, {
          id: '2',
          title: '昊享达企业信息管理系统试运行',
          abstract: '为中小企业的腾飞插上翅膀',
          src: require('../../../assets/dynamic/N2.jpg'),
          content: '<p style="text-indent: 24px">2020年9月，昊享达企业信息管理系统试运行，我们面向中小企业，将企业部分信息社会化，解决中小企业物料管理混乱，重复购买，物料丢失等问题，同时通过系统的广泛应用，企业之间可相互合作，解决找供应商和找客户的问题，促进中小企业的健康、快速发展。</p>'
        }, {
          id: '3',
          title: '柔性生产线仿真项目验收合格',
          abstract: '我们的生产线仿真更专业',
          src: require('../../../assets/dynamic/N3.jpg'),
          content: '<p style="text-indent: 24px">2021年3月，柔性生产线仿真项目经客户验收合格，该柔性生产线生产的产品品种多，每个工艺岛的加工时间差异大、不同产品在相同工位的加工时间也不同，建立模型复杂，控制更复杂，团队成员通过努力，克服了种种不利因素，将软件的能力发挥到极致，按时完成项目，我们在此点赞。</p>'
        }, {
          id: '4',
          title: '一种企业物料信息社会化管理方法及生态系统',
          abstract: '发明专利公布并进入实质审查阶段',
          src: require('../../../assets/dynamic/N4.jpg'),
          content: '<p style="text-indent: 24px">2021年5月10日，发明专利“一种企业物料信息社会化管理方法及生态系统”进行公布并进入实质审查阶段。</p>'
        }
      ]
    }
  },
  methods: {
    brouwerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    },
    getNews: function () {
      this.columnLen = this.isPC ? 4 : 1
      // console.log('columnLen:', this.columnLen)
      this.rowLen = Math.floor(this.newsList.length / this.columnLen)
      // console.log('rowLen:', this.rowLen)
      this.remainder = this.newsList.length % this.columnLen
      // console.log('remainder:', this.remainder)
    },
    handleNewsCard: function (id) {
      console.log('id:', id)
      if (id > 0) {
        this.$router.push({name: 'dynamicDetail', params: {title: this.newsList[id-1].title, src: this.newsList[id-1].src, content: this.newsList[id-1].content}})
      }
    }
  },
  created() {
    this.brouwerTest()
    this.getNews()
  }
}
</script>

<style scoped>

</style>