<template>
<div>
  <div v-if="isPC">
    <el-breadcrumb separator="/" style="margin-top: 10px">
      <el-breadcrumb-item :to="{ path: '/dynamic' }">动态信息</el-breadcrumb-item>
      <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
    </el-breadcrumb>
    <article>
      <h1 style="text-align: center">{{title}}</h1>
      <el-divider></el-divider>
      <div style="display: flex; justify-content: center"><img :src="src" width="100%" alt=""></div>
      <p v-html="content" style="padding-left: 25px; padding-right: 25px"></p>
    </article>
  </div>
  <div v-else style="margin-top: 50px">
    <el-breadcrumb separator="/" style="margin-top: 10px">
      <el-breadcrumb-item :to="{ path: '/dynamic' }">动态信息</el-breadcrumb-item>
      <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
    </el-breadcrumb>
    <article>
      <h1 style="text-align: center">{{title}}</h1>
      <el-divider></el-divider>
      <div style="display: flex; justify-content: center"><img :src="src" width="100%" alt=""></div>
      <p v-html="content" style="padding-left: 25px; padding-right: 25px"></p>
    </article>
  </div>

</div>
</template>

<script>
export default {
  name: "detail",
  data () {
    return {
      isPC: '',
      title: '',
      src: '',
      content: ''
    }
  },
  methods: {
    brouwerTest: function () {
      this.isPC = true
      let userAgent = navigator.userAgent.toLowerCase()
      if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
        this.isPC = false
      }
    },
    getNewsDetail: function () {
      this.title = this.$route.params.title
      this.src = this.$route.params.src
      this.content = this.$route.params.content
    }
  },
  created() {
    this.getNewsDetail()
  }
}
</script>

<style scoped>

</style>